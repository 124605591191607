g{
    transition: 1s;
}
svg:hover .g1{
    transform: translate(-5px,-100px);
    transition: 1s ;
}
svg:hover .g2{
    transform: translate(-20px,-100px);
    transition: 1s ;
}
svg:hover .g3{
    transform: translate(-30px,-100px);
    transition: 1s ;
}
*{
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
}