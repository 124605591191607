* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: Mont, serif;
  -webkit-backface-visibility: hidden;

  button {
    cursor: pointer;
    font-family: Mont, serif !important;
  }

  overflow: unset;
}
::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  background-color: #141926;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(139.47deg, #00ccffae -24.01%, #00ffa2a8 90.74%);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(139.47deg, #00a2ff -24.01%, #00ff62 90.74%);
}

@font-face {
  font-family: Mont;
  src: url(mont/Mont-HeavyDEMO.7d90a574d6ef3b7949d7.otf);
}

.main {
  width: 100%;
  min-height: 4710px;
  background-color: #141926;
  overflow: hidden;
}

.Nav {
  max-width: 655px;
  padding: 62px;
  display: flex;
  flex-wrap: wrap;
  color: white;
  font-size: 16.2035px;
  justify-content: space-between;

  li {
    list-style: none;
    display: inline-block;
    border-radius: 7px;
    padding: 0px 1px;
  }

  a {
    text-decoration: none;
    color: white;
  }
}

.logoBox {
  width: 100%;
  padding-top: 102px;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    width: 151px;
    height: 56px;
    background: linear-gradient(139.47deg, #00cbff -24.01%, #00ffa1 90.74%);
    border: none;
    outline: none;
    color: white;
    margin-top: 146px;
    border-radius: 17px;
  }
}

.aboutUs {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 272px auto auto;
  align-items: end;
  text-align: right;

  button {
    padding: 15px 50px;
    background: linear-gradient(139.47deg, #00cbff -24.01%, #00ffa1 90.74%);
    border-radius: 10px;
    outline: none;
    border: none;
    color: white;
    margin-top: 20px;
    cursor: pointer;
  }
}

.whatWeDo {
  max-width: 80%;
  padding-top: 450px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  transition: 1s;

  .cards {
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 50px;

    .card {
      border: 4px solid #00cbff;
      border-radius: 30px;
      padding: 30px 28px;
      margin-bottom: 40px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 264px;
      height: 446px;

      svg {
        color: #00cbff;
      }

      .div {
        width: 209px;
        overflow: hidden;
        text-align: center;
        color: white;

        h1 {
          font-size: 30.3815px;
          line-height: 39px;
          margin-bottom: 10px;
        }

        p {
          font-size: 12px;
          line-height: 21px;
          height: 130px;
        }
      }

      button {
        background: linear-gradient(139.47deg, #00cbff -24.01%, #00ffa1 90.74%);
        padding: 15px 35px;
        border-radius: 10px;
        outline: none;
        border: none;
        color: yellow;
        margin-top: 20px;
        cursor: pointer;
      }
    }

    .card:hover,
    .cardTrue:hover,
    .card1:hover,
    .cardTrue1:hover,
    .card2:hover,
    .cardTrue2:hover {
      background: linear-gradient(139.47deg, #00cbff -24.01%, #00ffa1 90.74%);

      h1 {
        color: #141926;
      }

      button {
        background: black;
      }

      svg {
        color: black;
      }
    }

    .card1 {
      border: 4px solid #00cbff;
      border-radius: 30px;
      padding: 30px 28px;
      margin-bottom: 40px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 264px;
      height: 446px;
      background: inherit;

      svg {
        color: #00cbff;
      }

      .div1 {
        width: 200px;
        overflow: hidden;
        text-align: center;
        color: white;

        h1 {
          font-size: 30.3815px;
          line-height: 39px;
          margin-bottom: 10px;
        }

        p {
          font-size: 12px;
          line-height: 21px;
          height: 130px;
        }
      }

      button {
        background: linear-gradient(139.47deg, #00cbff -24.01%, #00ffa1 90.74%);
        padding: 15px 35px;
        border-radius: 10px;
        outline: none;
        border: none;
        color: white;
        margin-top: 20px;
        cursor: pointer;
      }
    }

    .cardTrue1 {
      border: 4px solid #00cbff;
      border-radius: 30px;
      padding: 30px 28px;
      margin-bottom: 40px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 264px;
      height: 490px;

      svg {
        color: #00cbff;
      }

      .divTrue1 {
        width: 200px;
        text-align: center;
        color: white;
        height: 310px;

        h1 {
          font-size: 30.3815px;
          line-height: 39px;
          margin-bottom: 10px;
        }

        p {
          font-size: 12px;
          line-height: 21px;
          height: 130px;
        }
      }

      button {
        background: linear-gradient(139.47deg, #00cbff -24.01%, #00ffa1 90.74%);
        padding: 15px 35px;
        border-radius: 10px;
        outline: none;
        border: none;
        color: white;
        margin-top: 20px;
        cursor: pointer;
      }
    }

    .card2 {
      border: 4px solid #00cbff;
      border-radius: 30px;
      padding: 30px 28px;
      margin-bottom: 40px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 264px;
      height: 446px;

      svg {
        color: #00cbff;
      }

      .div2 {
        width: 200px;
        overflow: hidden;
        text-align: center;
        color: white;

        h1 {
          font-size: 30.3815px;
          line-height: 39px;
          margin-bottom: 10px;
        }

        p {
          font-size: 12px;
          line-height: 21px;
          height: 130px;
        }
      }

      button {
        background: linear-gradient(139.47deg, #00cbff -24.01%, #00ffa1 90.74%);
        padding: 15px 35px;
        border-radius: 10px;
        outline: none;
        border: none;
        color: white;
        margin-top: 20px;
        cursor: pointer;
      }
    }

    .card {
      border: 4px solid #00cbff;
      border-radius: 30px;
      padding: 30px 28px;
      margin-bottom: 40px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 264px;
      height: 446px;

      .div2 {
        width: 209px;
        overflow: hidden;
        text-align: center;
        color: white;
        margin-top: 5px;

        h1 {
          font-size: 30.3815px;
          line-height: 39px;
          margin-bottom: 10px;
        }

        p {
          font-size: 12px;
          line-height: 21px;
          height: 130px;
        }
      }

      button {
        background: linear-gradient(139.47deg, #00cbff -24.01%, #00ffa1 90.74%);
        padding: 15px 35px;
        border-radius: 10px;
        outline: none;
        border: none;
        color: white;
        margin-top: 20px;
        cursor: pointer;
      }
    }

    .cardTrue2 {
      border: 4px solid #00cbff;
      border-radius: 30px;
      padding: 30px 28px;
      margin-bottom: 40px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 264px;
      height: 453px;

      svg {
        color: #00cbff;
      }

      .divTrue2 {
        width: 200px;
        text-align: center;
        color: white;
        height: 310px;

        h1 {
          font-size: 30.3815px;
          line-height: 39px;
          margin-bottom: 10px;
        }

        p {
          font-size: 12px;
          line-height: 21px;
          height: 130px;
        }
      }

      button {
        background: linear-gradient(139.47deg, #00cbff -24.01%, #00ffa1 90.74%);
        padding: 15px 35px;
        border-radius: 10px;
        outline: none;
        border: none;
        color: white;
        margin-top: -10px;
        cursor: pointer;
      }
    }

    .cardTrue {
      border: 4px solid #00cbff;
      border-radius: 30px;
      padding: 30px 28px;
      margin-bottom: 40px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 264px;
      height: 446px;

      svg {
        color: #00cbff;
      }

      .divTrue2 {
        width: 209px;
        text-align: center;
        color: white;
        height: 287px;
        margin-top: 5px;

        h1 {
          font-size: 30.3815px;
          line-height: 39px;
          margin-bottom: 10px;
        }

        p {
          font-size: 12px;
          line-height: 21px;
          height: 130px;
        }
      }

      button {
        background: linear-gradient(139.47deg, #00cbff -24.01%, #00ffa1 90.74%);
        padding: 15px 35px;
        border-radius: 10px;
        outline: none;
        border: none;
        color: white;
        margin-top: -10px;
        cursor: pointer;
      }
    }
  }
}

.expert {
  color: white;
  width: 90%;
  margin: 215px auto auto;
  display: flex;
  align-items: end;
  flex-direction: column;
  position: relative;
}

.swiper {
  width: 100%;
  height: 500px;
  color: white;
  padding: 0px 35px !important;
  .swiper-button-next {
    right: 0px !important;
    width: 50px;
    height: 50px;
    background: linear-gradient(139.47deg, #00cbff -24.01%, #00ffa1 90.74%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
  }
  .swiper-button-prev {
    left: 0px !important;
    width: 50px;
    height: 50px;
    background: linear-gradient(139.47deg, #00cbff -24.01%, #00ffa1 90.74%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
  }
}
:root {
  --swiper-theme-color: rgb(255, 255, 255) !important;
  --swiper-navigation-size: 35px !important;
}
.partnersSwiper{
  width: 100%;
  height: 660px !important;
  display: flex;
  flex-direction: column;
  .ourPartners{
    font-size: 60px !important;
    margin: 0 auto;
    margin-top: 50px;
  }
}
.contact {
  width: 100%;
  color: white;
  padding-top: 178px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

button {
  font-family: Arial, serif;
  cursor: pointer;
}

.expertText {
  max-width: 600px;
  font-size: 15px;
  line-height: 21px;
  text-align: justify;
  transition: 1s;
}

.expertSvg {
  position: absolute;
  top: -79px;
  z-index: 1;
  width: 100%;
  min-width: 180px;
  transition: 1s;
}

@media screen and (max-width: 1200px) {
  .Nav {
    position: relative;
    z-index: 111;
  }
  .back1SVG {
    min-width: 45% !important;
  }
  .back2SVG {
    min-width: 45% !important;
  }

  .aboutUs {
    width: 50% !important;
    margin-right: 40px !important;
  }
}
@media screen and (max-width: 1100px) {
  .whatWeDo {
    padding-top: 250px !important;
  }
  .expert {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 1030px) {
  .Nav {
    max-width: 500px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
  }
  .back1SVG {
    width: 45% !important;
  }
  .expert {
    width: 95% !important;
  }
}
@media screen and (max-width: 950px) {
  .expert {
    align-items: center !important;
    margin-top: 0px !important;
  }
  .expertMainSvgBox {
    // top: -150px !important;
    position: unset !important;
    height: 620px !important;

    z-index: -2 !important;
  }
  .expertSvg {
    top: 0px !important;
    right: 40px !important;
    svg {
      height: 100% !important;
    }
  }
  .becomeAnExpert {
    position: absolute;
    top: 500px !important;
  }
}
@media screen and (max-width: 830px) {
  .Nav {
    max-width: 440px;
    li {
      font-size: 14px;
    }
  }
  .back1SVG {
    width: 45% !important;
  }
  .aboutUs {
    width: 500px !important;
  }

  .whatWeDo {
    padding-top: 90px !important;
  }
  .expertMainSvgBox {
    width: 380px !important;
  }
}
@media screen and (max-width: 750px) {
  .logoBox {
    padding-top: 40px;
    .weAreRisingExperts {
      font-size: 50px !important;
    }
    button {
      margin-top: 60px;
    }
  }
  .partnersSwiper{
    .ourPartners{
      font-size: 50px !important;
    }
  }
  .back2SVG {
    top: 30px !important;
  }
  .aboutUsText {
    font-size: 50px !important;
  }
  .aboutText {
    margin-top: 30px !important;
  }

  .whatWeDoText {
    font-size: 50px !important;
  }

  .expertMainSvgBox {
    height: 580px !important;
  }
  .becomeAnExpert {
    top: 480px !important;
    font-size: 50px !important;
  }
  .whatWeDo {
    max-width: 90% !important;
  }

  .contactH1 {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 650px) {
  .card,
  .card1,
  .card2 {
    width: 230px !important;
    height: 360px !important;
    padding: 15px 15px !important;
    h1 {
      font-size: 25px !important;
      line-height: 30px !important;
    }
  }
  .cardTrue,
  .cardTrue1,
  .cardTrue2 {
    width: 230px !important;
    padding: 15px !important;
    height: 400px !important;
    h1 {
      font-size: 25px !important;
      line-height: 30px !important;
    }
  }
}
@media screen and (max-width: 555px) {
  .back1SVG {
    width: 55% !important;
  }
  .back2SVG {
    top: 0px !important;
    min-width: 55% !important;
  }
  .logoBox {
    height: 385px !important;
    div {
      position: absolute;
      right: 10px;
      top: 100px;
    }
    .weAreRisingExperts {
      width: 330px !important;
      position: absolute;
      top: 130px;
      right: 10px;
      text-align: right !important;
      line-height: 60px !important;
    }
    button {
      position: absolute;
      right: 10px;
      top: 290px;
    }
  }
  .aboutUs {
    width: 91% !important;
    margin-right: 10px !important;
  }
  .expertMainSvgBox {
    width: 300px !important;
    height: 450px !important;
  }
  .becomeAnExpert {
    top: 350px !important;
  }
  .card,
  .card1,
  .card2 {
    margin-right: 8px !important;
  }
  .cardTrue,
  .cardTrue1,
  .cardTrue2 {
    margin-right: 8px !important;
  }
  .swiper {
    height: 420px !important;
    .swiper-button-next,
    .swiper-button-prev {
      top: 44% !important;
    }
  }
  .partnersSwiper{
    height: initial !important;
    .swiperBox{
      margin-top: 0 !important;
    }
    .swiper-button-prev{
      top: 33% !important;
    }
    .swiper-button-next{
      top: 33% !important;
    }
  }
  .swiperBox {
    height: 370px !important;
  }
  .contact {
    form {
      width: 400px !important;
      .contactText {
        .inpBox {
          padding-top: 5px !important;
        }
      }
    }
  }
  .main {
    min-height: 4500px !important;
  }
}
@media screen and (max-width: 508px) {
  .expertText{
    margin-top: 55px !important;
  }
}
@media screen and (max-width: 492px) {
  .contactIMG {
    top: unset !important;
  }
  .becomeAnExpert {
    line-height: 55px !important;
  }
  .expertText {
    margin-top: 50px !important;
  }
  .card,
  .card1,
  .card2 {
    margin-right: 0px !important;
  }
  .cardTrue,
  .cardTrue1,
  .cardTrue2 {
    margin-right: 0px !important;
  }
  .contact {
    padding-top: 70px !important;
  }
  .footer {
    img {
      width: 150px !important;
    }
    div {
      font-size: 12px !important;
    }
  }
}
@media screen and (max-width: 483px) {
  .swipperDiv {
    width: 250px !important;
    height: 250px !important;
  }
}
@media screen and (max-width: 458px) {
  .swipperDiv {
    width: 220px !important;
    height: 220px !important;
    img{
      border-radius: 20px !important;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 40px !important;
    height: 40px !important;
  }
  .swiper-button-next {
    padding-left: 3px !important;
  }
  .swiper-button-prev {
    padding-right: 3px !important;
  }
  :root {
    --swiper-navigation-size: 25px !important;
  }
}
@media screen and (max-width: 450px) {
  .Nav {
    li {
      font-size: 12px;
    }
  }
  .becomeAnExpert{
    text-align: center !important;
  }
  .logoBox {
    .back2SVG {
      min-width: 55% !important;
    }
    div {
      min-width: 200px !important;
    }
    .weAreRisingExperts {
      margin-top: 30px !important;
      font-size: 40px !important;
      line-height: 50px !important;
    }
    button {
      width: 130px !important;
      height: 40px !important;
      margin-top: 0px !important;
      border-radius: 10px !important;
    }
  }
  .aboutUs {
    margin-top: 150px !important;
    button {
      width: 130px !important;
      height: 40px !important;
      padding: 0;
    }
  }
  .aboutUsText {
    font-size: 40px !important;
  }
  .aboutText {
    margin-top: 10px !important;
  }

  .whatWeDo {
    padding-top: 50px !important;
  }
  .whatWeDoText {
    font-size: 40px !important;
  }
  .partnersSwiper{
    .ourPartners{
      font-size: 40px !important;
    }
  }
  .becomeAnExpert {
    font-size: 40px !important;
  }
  .expertText {
    margin-top: 25px !important;
  }

  .contactH1 {
    font-size: 40px !important;
  }
  .contactP {
    font-size: 14px !important;
    width: 80% !important;
  }
  .contact {
    padding-top: 20px !important;
  }
  .contact {
    form {
      width: 350px !important;
      .contactText {
        .inpBox {
          input {
            padding: 10px 15px !important;
          }
          textarea {
            padding: 10px 15px !important;
            max-width: 100% !important;
            max-height: 120px !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 428px) {
  .swipperDiv {
    width: 200px !important;
    height: 200px !important;
  }
}
@media screen and (max-width: 400px) {
  .Nav {
    flex-direction: column;
    padding-top: 20px !important;
    z-index: 0 !important;
    li {
      margin: 2px 0px !important  ;
    }
  }
  .logoBox {
    .back2SVG {
      top: 0px !important;
    }
    div {
      top: 70px !important;
    }
    .weAreRisingExperts {
      margin-top: 0px !important;
    }
    button {
      margin-top: -30px !important;
    }
  }
  .aboutUs {
    margin-top: 80px !important;
  }
  .contact {
    form {
      width: 300px !important;
      .contactText {
        .inpBox {
          input {
            padding: 10px 15px !important;
          }
          textarea {
            padding: 10px 15px !important;
            max-width: 100% !important;
            max-height: 120px !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 408px) {
  .swipperDiv {
    width: 180px !important;
    height: 180px !important;
  }
}
@media screen and (max-width: 395px) {
  .becomeAnExpert{
    text-align: center !important;
  }
}
@media screen and (max-width: 385px) {
  .swipperDiv {
    width: 165px !important;
    height: 165px !important;
  }
  .swiperBox {
    margin-top: 0px !important;
    height: 320px !important;
  }
  .swiper {
    height: 285px !important;
    .swiper-button-next,
    .swiper-button-prev {
      top: 39% !important;
      width: 35px !important;
      height: 35px !important;
    }
  }
  .partnersSwiper{
    height: 380px !important;
    .ourPartners{
      margin-top: 10px !important;
    }
  }
  :root {
    --swiper-navigation-size: 20px !important;
  }
}
@media screen and (max-width: 350px) {
  .logoBox {
    div {
      min-width: 170px !important;
      top: 40px !important;
    }
    .weAreRisingExperts {
      margin-top: -40px !important;
      font-size: 34px !important;
      width: 240px !important;
      line-height: 40px !important;
    }
    button {
      width: 100px !important;
      height: 30px !important;
      top: 220px !important;
      font-size: 12px !important;
      border-radius: 8px !important;
    }
  }
  .partnersSwiper{
    .ourPartners{
      font-size: 32px !important;
    }
  }
  .aboutUs {
    margin-top: 20px !important;
  }
  .aboutUsText {
    font-size: 34px !important;
  }

  .whatWeDoText {
    font-size: 34px !important;
  }

  .becomeAnExpert {
    font-size: 32px !important;
    top: 370px !important;
  }

  .contactH1 {
    font-size: 34px !important;
  }
  .cards {
    margin-top: 20px !important;
  }
  .footer {
    img {
      width: 130px !important;
    }
    div {
      font-size: 11px !important;
    }
  }
  .swipperDiv {
    margin-bottom: 5px !important;
  }
  p {
    margin-top: 5px !important;
  }
  .logoBox {
    .back2SVG {
      top: -50px !important;
      width: 65% !important;
    }
  }
}
@media screen and (max-width: 350px) {
  .swiper {
    .swiper-button-next,
    .swiper-button-prev {
      top: 44% !important;
    }
  }
}

.inpBox {
  padding-top: 28px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footer {
  width: 100%;
  height: 150px;
  background: linear-gradient(#141926 50%, black);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
